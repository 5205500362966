import { FC, ReactNode } from "react";

import CheckIcon from "@mui/icons-material/Check";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import {
  DateField,
  useGetOne,
  useRecordContext,
  useRefresh,
} from "react-admin";

import { useVapausMutation } from "hooks";

export interface CancelOrderModalProps {
  open: boolean;
  onClose: () => void;
  title?: string;
  description?: ReactNode;
}

export const CancelOrderModal: FC<CancelOrderModalProps> = ({
  open,
  onClose,
  title = "Cancel order",
  description = "Are you sure you want to cancel this order?",
}) => {
  const record = useRecordContext();
  const refresh = useRefresh();
  const { mutate, isLoading } = useVapausMutation("cancelBikeBenefitOrder");

  return (
    <Dialog fullWidth open={open} onClose={onClose} aria-label="Cancel order">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{description}</DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isLoading}>
          Go back
        </Button>
        <Button
          disabled={isLoading}
          startIcon={<CheckIcon />}
          onClick={() =>
            mutate(record.id, {
              onSuccess: () => refresh(),
            })
          }
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const CancelFinalisedOrderModal: FC<CancelOrderModalProps> = (props) => {
  const record = useRecordContext();

  const { data: contract } = useGetOne(
    "bike-benefit-contracts",
    { id: record?.current_bike_benefit_contract_id },
    {
      enabled: !!record?.current_bike_benefit_contract_id,
    },
  );

  return (
    <CancelOrderModal
      {...props}
      title="Are you sure?"
      description={
        <>
          This will invalidate the contract {record?.vapaus_code} started on{" "}
          <DateField record={contract} source="start_date" />. All B2B billing
          done up until today should be reverted.
        </>
      }
    />
  );
};
