import { useState } from "react";

import CalculateIcon from "@mui/icons-material/Calculate";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import GavelIcon from "@mui/icons-material/Gavel";
import { CreatePurchaseInvoiceModal } from "components/CreatePurchaseInvoiceModal";
import { BikeBenefitContractState, BikeBenefitOrderState } from "core/benefit";
import { useGetOne, useRecordContext } from "ra-core";
import { useRedirect } from "react-admin";

import {
  CancelFinalisedOrderModal,
  CancelOrderModal,
} from "./CancelOrderModal";
import { FinaliseOrderModal } from "./FinaliseOrderModal";
import { DropdownIconMenu } from "components";

enum MenuType {
  ADD_PURCHASE_INVOICE = "add_purchase_invoice",
  CANCEL_ORDER = "cancel_order",
  FINALIZE_ORDER = "finalize_order",
}

const OrderMenu = () => {
  const record = useRecordContext();
  const redirect = useRedirect();
  const [menu, setMenu] = useState<MenuType>();

  const hasOpenStatus = [
    BikeBenefitOrderState.DRAFT,
    BikeBenefitOrderState.WAITING_FOR_PAYMENT,
    BikeBenefitOrderState.READY_FOR_DELIVERY,
  ].includes(record?.state);

  const showFinalize =
    record?.state === BikeBenefitOrderState.READY_FOR_DELIVERY;

  const editOrderMenu = hasOpenStatus
    ? [
        {
          name: "Edit order",
          icon: <EditIcon />,
          onClick: () => redirect(`/bike-benefit-orders/${record.id}/edit`),
          Component: null,
          menuType: null,
        },
      ]
    : [];

  const cancelOrderMenu = hasOpenStatus
    ? [
        {
          name: "Cancel order",
          icon: <CancelIcon />,
          onClick: () => setMenu(MenuType.CANCEL_ORDER),
          Component: CancelOrderModal,
          menuType: MenuType.CANCEL_ORDER,
        },
      ]
    : [];

  const finaliseOrderMenu = showFinalize
    ? [
        {
          name: "Finalise Order",
          icon: <GavelIcon />,
          onClick: () => setMenu(MenuType.FINALIZE_ORDER),
          Component: FinaliseOrderModal,
          menuType: MenuType.FINALIZE_ORDER,
        },
      ]
    : [];

  const { data: contract } = useGetOne(
    "bike-benefit-contracts",
    { id: record?.current_bike_benefit_contract_id },
    {
      enabled: !!record?.current_bike_benefit_contract_id,
    },
  );

  const cancelFinalisedOrderMenu =
    BikeBenefitContractState.ACTIVE === contract?.state
      ? [
          {
            name: "Cancel finalised order",
            icon: <CancelIcon />,
            onClick: () => setMenu(MenuType.CANCEL_ORDER),
            Component: CancelFinalisedOrderModal,
            menuType: MenuType.CANCEL_ORDER,
            disabled: Boolean(
              record?.revises_bike_benefit_contract_id ||
                record?.corrects_bike_benefit_contract_id,
            ),
          },
        ]
      : [];

  const menus = [
    {
      name: "Add Purchase Invoice",
      icon: <CalculateIcon />,
      onClick: () => setMenu(MenuType.ADD_PURCHASE_INVOICE),
      menuType: MenuType.ADD_PURCHASE_INVOICE,
      Component: undefined,
    },
    ...editOrderMenu,
    ...cancelOrderMenu,
    ...finaliseOrderMenu,
    ...cancelFinalisedOrderMenu,
  ];

  return (
    <>
      <DropdownIconMenu menus={menus} tooltip="Order Actions" />
      <CreatePurchaseInvoiceModal
        view="order"
        open={menu === MenuType.ADD_PURCHASE_INVOICE}
        onClose={() => setMenu(undefined)}
      />
      {menus.map(
        ({ Component, name, menuType }) =>
          Component && (
            <Component
              key={name}
              open={menu === menuType}
              onClose={() => setMenu(undefined)}
            />
          ),
      )}
    </>
  );
};

export default OrderMenu;
