import {
  BooleanField,
  BooleanInput,
  Datagrid,
  FunctionField,
  List,
  ReferenceField,
  SelectInput,
  TextField,
  TextInput,
} from "react-admin";

import {
  BenefitDefinitionReadWithOrganisationSummary,
  Plan,
} from "@vapaus/generated";

import { Pagination } from "components";
import { AVAILABLE_COUNTRIES, PLANS_LABELS } from "core";

const filters = [
  <TextInput label="Search" source="q" alwaysOn />,
  <BooleanInput label="Active" source="is_active" />,
  <SelectInput source="country" choices={AVAILABLE_COUNTRIES} />,
];

export const BenefitDefinitionList = () => (
  <List filters={filters} perPage={20} pagination={<Pagination />}>
    <Datagrid bulkActionButtons={false} rowClick="edit">
      <TextField source="name" />
      <TextField label="Description" source="description" sortable={false} />
      <ReferenceField
        source="organisation_id"
        reference="organisations"
        sortable={false}
      >
        <TextField source="name" />
      </ReferenceField>
      <FunctionField<BenefitDefinitionReadWithOrganisationSummary>
        label="Plan"
        render={(benefit) => PLANS_LABELS[benefit!.plan as Plan]}
        source="plan"
      />
      <BooleanField source="active" sortable={false} />
    </Datagrid>
  </List>
);
