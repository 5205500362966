import React, { FC } from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";

import { AnimatedIcon, Loading } from "../../../components";
import { useVapausQuery } from "hooks";

type DialogProps = {
  isOpen: boolean;
  setShowDialog: (open: boolean) => void;
  synchronisationId: string;
  erpSystem: string;
};

const InvoicesSynchronisationStatusDialog: FC<DialogProps> = ({
  isOpen,
  setShowDialog,
  synchronisationId,
  erpSystem,
}) => {
  const { data: status }: { data: any } = useVapausQuery(
    "getMonthlyInvoicesSynchronisationStatus",
    { synchronisation_id: synchronisationId, erpSystem: erpSystem },
    { refetchInterval: 5000, enabled: isOpen },
  );
  return (
    <Dialog
      fullWidth
      open={isOpen}
      onClose={() => setShowDialog(false)}
      aria-label="Invoices synchronisation status"
    >
      <DialogTitle>Invoices synchronisation status</DialogTitle>
      <DialogContent>
        {!status?.is_finished && (
          <>
            <Typography>
              Invoices are now being processed in {erpSystem} (synchronisation
              ID: {synchronisationId}).
            </Typography>
            <Typography>
              It might take up to a few minutes for the processing to finish.
            </Typography>
            <Typography>
              Please leave this dialog open to see the processing status.
            </Typography>
            <Loading />
          </>
        )}
        {status?.is_finished && status?.errors?.length ? (
          <>
            <Grid container justifyContent="center">
              <AnimatedIcon key="animation" type="error" />
            </Grid>
            <Typography>
              Invoices synchronisation has failed with errors listed below.
            </Typography>
            {status.errors.map(
              (error: { customer_code: string; message: string }) => (
                <Typography key={error.customer_code + "|" + error.message}>
                  Customer code {error.customer_code}: {error.message}
                </Typography>
              ),
            )}
          </>
        ) : null}
        {status?.is_finished && !status?.errors?.length ? (
          <>
            <Typography>
              Invoices have been successfully imported to {erpSystem}.
            </Typography>
            <Grid container justifyContent="center">
              <AnimatedIcon key="animation" type="success" />
            </Grid>
          </>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setShowDialog(false)} startIcon={<CancelIcon />}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InvoicesSynchronisationStatusDialog;
