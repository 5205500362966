import React, { useEffect } from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { DateTime } from "luxon";
import {
  Create,
  DateInput,
  Form,
  SaveButton,
  SelectInput,
  required,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { useFormContext } from "react-hook-form";

import { BikeBenefitContractReturnOrRedemptionReason } from "@vapaus/generated";

import { useEnumChoices } from "../../../hooks";
import { ContractMenuModalProps } from "./ContractMenu";

export const CreateRedemptionProcessModal = ({
  onClose,
  open,
}: ContractMenuModalProps) => {
  const contract = useRecordContext();
  const refresh = useRefresh();
  const notify = useNotify();
  const now = DateTime.now();
  const defaultPlannedRedemptionDate = DateTime.min(
    now.day <= 20 ? now.endOf("month") : now.plus({ month: 1 }).endOf("month"),
    DateTime.fromISO(contract.end_date),
  ).toISODate();

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      aria-label="Start Redemption Process"
    >
      <DialogTitle>Start Redemption Process</DialogTitle>
      <Create
        resource="redemption-processes"
        record={{
          bike_benefit_contract_id: contract.id,
          planned_redemption_date: defaultPlannedRedemptionDate,
        }}
        mutationOptions={{
          onSuccess: () => {
            refresh();
            notify("Redemption Process started successfully");
            onClose();
          },
        }}
        title={<></>}
        sx={{ mt: "-2em" }}
      >
        <Form>
          <DialogContent>
            Starting the Redemption Process the system will issue a redemption
            fee invoice.
            <DateInput source="planned_redemption_date" fullWidth />
            <RedemptionReasonSelectInput contractEndDate={contract.end_date} />
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} startIcon={<CancelIcon />}>
              Cancel
            </Button>
            <SaveButton alwaysEnable />
          </DialogActions>
        </Form>
      </Create>
    </Dialog>
  );
};

const RedemptionReasonSelectInput = ({
  contractEndDate,
}: {
  contractEndDate: DateTime;
}) => {
  const redemptionReasonChoices = useEnumChoices(
    "BikeBenefitContractReturnOrRedemptionReason",
  );
  const redemptionReasonChoicesFiltered = redemptionReasonChoices.filter(
    (choice) =>
      choice.id !== BikeBenefitContractReturnOrRedemptionReason.END_OF_LEASE,
  );
  const { watch, setValue } = useFormContext();
  const redemptionDate = watch("planned_redemption_date");
  const endOfLeaseRedemption = redemptionDate === contractEndDate;

  useEffect(() => {
    if (endOfLeaseRedemption) {
      setValue(
        "redemption_reason",
        BikeBenefitContractReturnOrRedemptionReason.END_OF_LEASE,
      );
      return;
    }
    setValue("redemption_reason", undefined);
  }, [endOfLeaseRedemption, setValue]);

  return (
    <SelectInput
      source="redemption_reason"
      label="Redemption reason"
      choices={
        endOfLeaseRedemption
          ? redemptionReasonChoices
          : redemptionReasonChoicesFiltered
      }
      validate={[required()]}
      fullWidth
      disabled={endOfLeaseRedemption}
    />
  );
};
