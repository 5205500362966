import {
  Datagrid,
  FunctionField,
  List,
  ReferenceField,
  SelectInput,
  TextField,
  TextInput,
} from "react-admin";

import { OrganisationRead, Plan } from "@vapaus/generated";

import { Pagination } from "components";
import { AVAILABLE_COUNTRIES, PLANS_LABELS } from "core";

const organisationFilters = [
  <TextInput label="Search" source="q" alwaysOn />,
  <SelectInput source="country" choices={AVAILABLE_COUNTRIES} />,
];

export const OrganisationList = () => (
  <List filters={organisationFilters} perPage={20} pagination={<Pagination />}>
    <Datagrid bulkActionButtons={false} rowClick="edit">
      <TextField source="name" />
      <TextField label="Business ID" source="business_id" sortable={false} />
      <ReferenceField
        source="parent_id"
        reference="organisations"
        sortable={false}
      >
        <TextField source="name" />
      </ReferenceField>
      <FunctionField<OrganisationRead>
        label="Plan"
        render={(organisation) => PLANS_LABELS[organisation!.plan as Plan]}
        source="plan"
      />
    </Datagrid>
  </List>
);
