import { FC, useState } from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  Typography,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import TextField from "@mui/material/TextField";
import { DateTime } from "luxon";
import {
  FunctionField,
  Labeled,
  NumberField,
  RecordContextProvider,
  useRecordContext,
  useTranslate,
} from "react-admin";

import { RedemptionCalculation } from "@vapaus/generated";

import { ContractMenuModalProps } from "./ContractMenu";
import { RowItem } from "./RowItem";
import { useVapausQuery } from "hooks";

export const RedemptionPriceModal: FC<ContractMenuModalProps> = ({
  onClose,
  open,
}) => {
  const translate = useTranslate();
  const contract = useRecordContext();

  const [endDate, setEndDate] = useState(DateTime.now().toISODate());
  const { data, isLoading, error } = useVapausQuery<RedemptionCalculation>(
    "getRedemptionPrice",
    {
      bike_benefit_contract_id: contract?.id,
      exceptional_end_date: endDate,
    },
    {
      retry: false,
      keepPreviousData: true,
    },
  );

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      aria-label="Redemption Price Calculator"
    >
      <DialogTitle>Redemption Price Calculator</DialogTitle>
      <DialogContent>
        <RecordContextProvider value={data ?? {}}>
          <TextField
            type="date"
            label="End date"
            fullWidth
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
          {error ? <Alert severity="error">{error.message}</Alert> : null}
          {data ? (
            <>
              <Typography variant="h6">Contract details</Typography>
              <Labeled fullWidth>
                <NumberField
                  source="taxable_package_price"
                  options={{ style: "currency", currency: contract.currency }}
                  record={contract}
                  label="Total without maintenance"
                />
              </Labeled>
              <Labeled fullWidth>
                <FunctionField
                  render={() => (
                    <>
                      <NumberField
                        source="monthly_leasing_fee"
                        options={{
                          style: "currency",
                          currency: contract.currency,
                        }}
                        record={contract}
                      />{" "}
                      (out of{" "}
                      {contract.fixed_monthly_taxable_value ? (
                        <NumberField
                          source="fixed_monthly_taxable_value"
                          options={{
                            style: "currency",
                            currency: contract.currency,
                          }}
                          record={contract}
                        />
                      ) : (
                        <NumberField
                          source="monthly_taxable_value"
                          options={{
                            style: "currency",
                            currency: contract.currency,
                          }}
                          record={contract}
                        />
                      )}
                      )
                    </>
                  )}
                  label="Monthly repayment"
                />
              </Labeled>

              <Typography variant="h6">Benefit parameters</Typography>
              <Labeled fullWidth>
                <NumberField
                  source="redemption_percentage"
                  options={{ style: "percent", maximumFractionDigits: 2 }}
                  label="Residual ratio"
                />
              </Labeled>
              <Labeled fullWidth>
                <NumberField
                  source="redemption_fee"
                  options={{ style: "currency", currency: contract.currency }}
                />
              </Labeled>
              <Labeled fullWidth>
                <NumberField
                  source="discount"
                  options={{ style: "currency", currency: contract.currency }}
                />
              </Labeled>

              <Typography variant="h6">Current standing</Typography>
              <Labeled fullWidth>
                <FunctionField
                  label="Months since delivered"
                  render={() => (
                    <>
                      <NumberField source="months_since_delivered" />
                      /
                      <NumberField
                        source="leasing_period_months"
                        options={{
                          style: "unit",
                          unit: "month",
                          unitDisplay: "long",
                        }}
                        record={contract}
                      />
                    </>
                  )}
                />
              </Labeled>
              <Labeled fullWidth>
                <FunctionField
                  label={
                    <>
                      Paid value <b>without</b> financing and insurance costs
                    </>
                  }
                  render={() => (
                    <>
                      <NumberField
                        source="leasing_fees_paid"
                        options={{
                          style: "currency",
                          currency: contract.currency,
                        }}
                      />
                      /
                      <NumberField
                        source="leasing_fees_total"
                        options={{
                          style: "currency",
                          currency: contract.currency,
                        }}
                      />
                    </>
                  )}
                />
              </Labeled>
              <Labeled fullWidth>
                <FunctionField
                  label={
                    <>
                      Paid value <b>with</b> financing and insurance costs
                    </>
                  }
                  render={() => (
                    <>
                      <NumberField
                        source="monthly_payments_paid"
                        options={{
                          style: "currency",
                          currency: contract.currency,
                        }}
                      />
                      /
                      <NumberField
                        source="monthly_payments_total"
                        options={{
                          style: "currency",
                          currency: contract.currency,
                        }}
                      />
                    </>
                  )}
                />
              </Labeled>
              {data.overspent_maintenance_budget ? (
                <Labeled fullWidth>
                  <FunctionField
                    label="Overspent maintenance budget"
                    render={() => (
                      <>
                        <NumberField
                          source="overspent_maintenance_budget"
                          options={{
                            style: "currency",
                            currency: contract.currency,
                          }}
                        />{" "}
                        (spent:{" "}
                        <NumberField
                          source="spent_maintenance_budget"
                          options={{
                            style: "currency",
                            currency: contract.currency,
                          }}
                          record={contract}
                        />{" "}
                        - accumulated:{" "}
                        <NumberField
                          source="accumulated_maintenance_budget"
                          options={{
                            style: "currency",
                            currency: contract.currency,
                          }}
                        />
                        )
                      </>
                    )}
                  />
                </Labeled>
              ) : null}
              <Card variant="outlined" sx={{ mt: 2 }}>
                <Table>
                  <RowItem label="Redemption price" fullWidth>
                    <NumberField
                      source="redemption_price"
                      options={{
                        style: "currency",
                        currency: contract.currency,
                      }}
                    />
                  </RowItem>
                </Table>
              </Card>
            </>
          ) : null}
        </RecordContextProvider>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          disabled={isLoading}
          startIcon={<CancelIcon />}
        >
          {translate("ra.action.cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
