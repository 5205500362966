import { FC, useEffect } from "react";

import {
  AutocompleteInput,
  Datagrid,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
} from "react-admin";
import { useFormContext } from "react-hook-form";

import { Pagination } from "components";
import {
  AVAILABLE_COUNTRIES,
  BIKE_BENEFIT_CONTRACT_STATES_CHOICES,
} from "core";

const BenefitDefinitionFilter = ({
  label,
  source,
}: {
  label: string;
  source: string;
}) => {
  const { watch, setValue } = useFormContext();
  const organisationId = watch("organisation_id");
  useEffect(() => {
    setValue(source, null);
  }, [organisationId]);
  return (
    <ReferenceInput
      source={source}
      reference="benefit-definitions"
      filter={{ organisation_id: organisationId }}
      enableGetChoices={() => !!organisationId}
    >
      {organisationId ? (
        <AutocompleteInput
          label={label}
          optionText="name"
          optionValue="id"
          fullWidth
        />
      ) : (
        <SelectInput
          label={label}
          source=""
          defaultValue={"empty"}
          choices={[{ id: "empty", name: "select organisation" }]}
          disabled
        />
      )}
    </ReferenceInput>
  );
};

const filters = [
  <TextInput label="Search" source="q" alwaysOn />,
  <ReferenceInput source="organisation_id" reference="organisations" alwaysOn>
    <AutocompleteInput
      label="Organisation"
      optionText="name"
      optionValue="id"
    />
  </ReferenceInput>,
  <SelectInput source="country" choices={AVAILABLE_COUNTRIES} />,
  <BenefitDefinitionFilter
    label="Benefit definition"
    source="benefit_definition_id"
  />,
  <ReferenceInput source="user_id" reference="users">
    <AutocompleteInput
      label="User"
      optionText={(option) => `${option.full_name || ""} (${option.email})`}
      optionValue="id"
    />
  </ReferenceInput>,
  <SelectInput
    label="Status"
    source="states"
    choices={BIKE_BENEFIT_CONTRACT_STATES_CHOICES}
  />,
  <ReferenceInput source="shop_id" reference="shops">
    <AutocompleteInput label="Shop" optionText="name" optionValue="id" />
  </ReferenceInput>,
];

export const ContractList: FC = () => (
  <List filters={filters} perPage={20} pagination={<Pagination />}>
    <Datagrid bulkActionButtons={false} rowClick="show">
      <TextField source="vapaus_code" sortable={false} />
      <TextField source="user.full_name" />
      <ReferenceField
        source="benefit_definition_id"
        reference="benefit-definitions"
        sortBy="benefit_definition.name"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="state" textTransform="uppercase" label="Status" />
      <TextField source="bike.serial_number" label="Bike frame number" />
      <ReferenceField
        source="bike_benefit_order_id"
        reference="bike-benefit-orders"
        label="Shop"
        sortable={false}
      >
        <ReferenceField source="shop_id" reference="shops">
          <TextField source="name" />
        </ReferenceField>
      </ReferenceField>
    </Datagrid>
  </List>
);
