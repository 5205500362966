import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  SelectInput,
  TextField,
  TextInput,
} from "react-admin";

import { Pagination } from "components";
import { AVAILABLE_COUNTRIES } from "core";
import { useEnumChoices } from "hooks";

export const ReturnProcessList = () => {
  const statusChoices = useEnumChoices("ReturnProcessStatus");
  const filters = [
    <TextInput source="q" label="Search" alwaysOn />,
    <SelectInput
      source="status"
      label="Status"
      choices={statusChoices}
      alwaysOn
    />,
    <SelectInput source="country" choices={AVAILABLE_COUNTRIES} />,
  ];
  return (
    <List
      filters={filters}
      perPage={20}
      pagination={<Pagination />}
      sort={{ field: "created_at", order: "DESC" }}
    >
      <Datagrid bulkActionButtons={false} rowClick="show">
        <ReferenceField
          source="bike_benefit_contract_id"
          reference="bike-benefit-contracts"
          sortable={false}
        >
          <TextField source="vapaus_code" />
        </ReferenceField>
        <ReferenceField
          source="bike_benefit_contract_id"
          reference="bike-benefit-contracts"
          sortable={false}
          label="Employee full name"
        >
          <TextField source="user.full_name" />
        </ReferenceField>
        <DateField source="created_at" label="Initiated at" />
        <TextField source="status" />
        <TextField source="return_reason" />
      </Datagrid>
    </List>
  );
};
