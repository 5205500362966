import React, { useEffect } from "react";

import {
  AutocompleteInput,
  HttpError,
  ReferenceInput,
  required,
  useGetOne,
  useRecordContext,
} from "react-admin";
import { useFormContext } from "react-hook-form";

import { UserRead } from "@vapaus/generated";

import { BenefitDefinitionDisplay } from "./BenefitDefinitionDisplay";
import { HubspotImport } from "./HubspotImport";
import { useHubspotTicketContext } from "./HubspotTicketContext";

export const OrderFormUser = () => {
  const record = useRecordContext();
  const { watch, setValue, setError, clearErrors } = useFormContext();
  const { hubspotTicketData } = useHubspotTicketContext();
  const selectedUserId = watch("user_id");
  const benefitDefinitionId = watch("benefit_definition_id");

  const shouldValidateUser = selectedUserId !== record?.user_id;
  const validatedUser = useGetOne<UserRead>(
    "users",
    {
      id: selectedUserId,
      meta: {
        queryParams: shouldValidateUser
          ? { validate_for_ordering: true }
          : undefined,
      },
    },
    {
      enabled: !!selectedUserId,
      retry: false,
    },
  );
  const { data: benefitDefinition } = useGetOne(
    "benefit-definitions",
    { id: benefitDefinitionId },
    {
      enabled: !!benefitDefinitionId,
      onSuccess: (benefitDefinition) => {
        setValue(
          "benefit_definition_terms_id",
          benefitDefinition.current_benefit_definition_term.id,
        );
      },
    },
  );
  useEffect(() => {
    if (validatedUser.isError) {
      const error = validatedUser.error as HttpError;
      setError("user_id", { message: error.message });
    } else {
      clearErrors("user_id");
    }
  }, [validatedUser.isError, validatedUser.error]);

  useEffect(() => {
    if (hubspotTicketData.userId) {
      setValue("user_id", hubspotTicketData.userId, {
        shouldTouch: true,
        shouldDirty: true,
      });
    }
  }, [hubspotTicketData.userId]);

  return (
    <>
      <HubspotImport />
      <ReferenceInput source="user_id" reference="users" isRequired>
        <AutocompleteInput
          label="Select the beneficiary"
          helperText={
            <>
              If the beneficiary doesn't have an account, one can be created at:{" "}
              <a href="https://user.vapaus.io" target="_blank">
                user.vapaus.io
              </a>
              .
            </>
          }
          optionText={(option) => `${option.full_name || ""} (${option.email})`}
          optionValue="id"
          disableClearable
          fullWidth
          validate={required()}
        />
      </ReferenceInput>
      {validatedUser.data?.id && (
        <>
          <ReferenceInput
            source="benefit_definition_id"
            reference="benefit-definitions"
            filter={{ available_for_user_id: selectedUserId }}
            isRequired
          >
            <AutocompleteInput
              label="Select the benefit"
              optionText={(option) =>
                `${option.organisation.name || ""} (${option.name})`
              }
              optionValue="id"
              fullWidth
              validate={required()}
            />
          </ReferenceInput>
          <BenefitDefinitionDisplay benefitDefinition={benefitDefinition} />
        </>
      )}
    </>
  );
};
