/**
 * This is were we can add custom methods for endpoint that are not supported out of the box by React Admin.
 */
import {
  AdminBikeBenefitOrderPartialUpdate,
  BikeUpdate,
} from "@vapaus/generated";

import { fetchJson } from "./fetchJson";

export type CustomMethodsNames =
  | "sendInvitationEmail"
  | "activateTermsAndConditions"
  | "activateBenefitDefinitionTerms"
  | "finaliseBikeBenefitOrder"
  | "cancelBikeBenefitOrder"
  | "downloadReport"
  | "contractCreateOrderCorrection"
  | "contractCreateOrderRevision"
  | "createSaleInvoiceTransaction"
  | "getRedemptionPrice"
  | "calculateBenefitShortestPeriod"
  | "calculateBenefitFixedPeriod"
  | "calculateBenefitFixedPeriodSwedenPilot"
  | "calculateBenefitFixedMonthlyPayment"
  | "getEnums"
  | "searchUserEmails"
  | "generateMonthlyInvoices"
  | "getMonthlyInvoicesSynchronisationStatus"
  | "processBenefitDefinitionMerger"
  | "makeUserEmailPrimary"
  | "partialUpdateBikeBenefitOrder"
  | "activateFleet"
  | "deactivateFleet"
  | "activateVehicle"
  | "deactivateVehicle"
  | "getBikeFieldSuggestions"
  | "importBikeOrderFromHubspot"
  | "updateBike"
  | "getUserNotificationsPreferences"
  | "downloadDeedOfSale"
  | "checkBikeData"
  | "planReturnProcessInvoicing"
  | "getReturnProcessChanges"
  | "uploadReturnProcessPicture"
  | "uploadOrganisationPicture"
  | "uploadShopPicture"
  | "getProductVatRates"
  | "getSwedenSuggestedBenefitValuesForContract"
  | "getSwedenSuggestedBenefitValuesForOrder";

export type CustomMethodsProvider = {
  [key in CustomMethodsNames]: (params: any) => Promise<any>;
};

const createCustomMethodsProvider = (
  apiUrl: string,
  httpClient = fetchJson,
): CustomMethodsProvider => ({
  sendInvitationEmail: async (userId: string) => {
    await httpClient(`${apiUrl}/users/${userId}/send-invitation-email`, {
      method: "POST",
    });
  },
  activateTermsAndConditions: async (termsAndConditionsId: string) => {
    const { json } = await httpClient(
      `${apiUrl}/terms/${termsAndConditionsId}/activate`,
      {
        method: "POST",
      },
    );
    return { data: json };
  },
  activateBenefitDefinitionTerms: async (termsAndConditionsId: string) => {
    const { json } = await httpClient(
      `${apiUrl}/benefit-definition-terms/${termsAndConditionsId}/activate`,
      {
        method: "POST",
      },
    );
    return { data: json };
  },
  downloadReport: async (type: string, params?: any) => {
    const filteredParams: Record<string, any> = Object.fromEntries(
      Object.entries(params || {}).filter(([_, v]) => v != null),
    );
    const urlParams = new URLSearchParams();
    Object.entries(filteredParams).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((value) => urlParams.append(key, value));
      } else {
        urlParams.append(key, value);
      }
    });
    const { body, headers } = await httpClient(
      `${apiUrl}/reports/${type}?${urlParams.toString()}`,
      {
        method: "GET",
      },
    );

    const filename = headers.get("content-disposition")?.split("filename=")[1];
    // httpClient (fetchJSON) uses `response.text()` internally and removes the BOM prefix from CSV files
    // we need to add it forcibly to make sure they open fine in Excel
    const bom = "\ufeff";
    return { body: bom + body, filename };
  },
  finaliseBikeBenefitOrder: async ({
    id,
    date,
    bike_serial_number,
  }: {
    id: string;
    date: string;
    bike_serial_number: string;
  }) => {
    const { json } = await httpClient(
      `${apiUrl}/bike-benefit-orders/${id}/finalise`,
      {
        method: "POST",
        body: JSON.stringify({ date, bike_serial_number }),
      },
    );
    return { data: json };
  },
  cancelBikeBenefitOrder: async (id: string) => {
    const { json } = await httpClient(
      `${apiUrl}/bike-benefit-orders/${id}/cancel`,
      {
        method: "POST",
      },
    );
    return { data: json };
  },
  createSaleInvoiceTransaction: async ({
    sale_invoice_id,
    date,
    amount,
    description,
    currency,
  }: {
    sale_invoice_id: string;
    date: string;
    amount: number;
    description: string;
    currency: string;
  }) => {
    const { json } = await httpClient(
      `${apiUrl}/invoices/sale-invoice-transactions`,
      {
        method: "POST",
        body: JSON.stringify({
          sale_invoice_id,
          date,
          amount,
          description,
          currency,
        }),
      },
    );
    return { data: json };
  },
  contractCreateOrderCorrection: async (order: any) => {
    const { json } = await httpClient(
      `${apiUrl}/bike-benefit-orders/correction`,
      {
        method: "POST",
        body: JSON.stringify(order),
      },
    );
    return { data: json };
  },
  contractCreateOrderRevision: async (order: any) => {
    const { json } = await httpClient(
      `${apiUrl}/bike-benefit-orders/revision`,
      {
        method: "POST",
        body: JSON.stringify(order),
      },
    );
    return { data: json };
  },
  getRedemptionPrice: async (params: any) => {
    const { json } = await httpClient(
      `${apiUrl}/calculations/redemption-price?${
        params ? new URLSearchParams(params).toString() : ""
      }`,
      {
        method: "GET",
      },
    );
    return json;
  },
  calculateBenefitShortestPeriod: async (params: any) => {
    const { json } = await httpClient(
      `${apiUrl}/../api/calculations/bike-benefit-shortest-period`,
      {
        method: "POST",
        body: JSON.stringify(params),
      },
    );
    return json;
  },
  calculateBenefitFixedPeriod: async (params: any) => {
    const { json } = await httpClient(
      `${apiUrl}/../api/calculations/bike-benefit-fixed-period`,
      {
        method: "POST",
        body: JSON.stringify(params),
      },
    );
    return json;
  },
  calculateBenefitFixedMonthlyPayment: async (params: any) => {
    const { json } = await httpClient(
      `${apiUrl}/../api/calculations/bike-benefit-fixed-monthly-payment`,
      {
        method: "POST",
        body: JSON.stringify(params),
      },
    );
    return json;
  },
  calculateBenefitFixedPeriodSwedenPilot: async (params: any) => {
    const { json } = await httpClient(
      `${apiUrl}/../api/calculations/bike-benefit-fixed-period-sweden-pilot`,
      {
        method: "POST",
        body: JSON.stringify(params),
      },
    );
    return json;
  },
  getEnums: async () => {
    const { json } = await httpClient(`${apiUrl}/../api/enums`, {
      method: "GET",
    });
    return json;
  },
  searchUserEmails: async (params: { user_id: string; q?: string }) => {
    const { json } = await httpClient(
      `${apiUrl}/user-emails?${new URLSearchParams(params).toString()}`,
      {
        method: "GET",
      },
    );
    return { data: json };
  },
  generateMonthlyInvoices: async (params: any) => {
    const { json } = await httpClient(
      `${apiUrl}/billing/generate-monthly-invoices`,
      {
        method: "POST",
        body: JSON.stringify(params),
      },
    );
    return json;
  },

  getMonthlyInvoicesSynchronisationStatus: async ({
    synchronisation_id,
    erpSystem,
  }: {
    synchronisation_id: string;
    erpSystem: string;
  }) => {
    const { json } = await httpClient(
      `${apiUrl}/billing/monthly-invoices-erp-status/${erpSystem}/${synchronisation_id}`,
      { method: "GET" },
    );
    return json;
  },
  processBenefitDefinitionMerger: async ({
    id,
    params,
  }: {
    id: string;
    params: any;
  }) => {
    const { json } = await httpClient(
      `${apiUrl}/benefit-definitions/${id}/process-merger`,
      {
        method: "POST",
        body: JSON.stringify(params),
      },
    );
    return json;
  },
  makeUserEmailPrimary: async (userEmailId: string) => {
    await httpClient(`${apiUrl}/user-emails/${userEmailId}/make-primary`, {
      method: "POST",
    });
  },
  partialUpdateBikeBenefitOrder: async ({
    id,
    params,
  }: {
    id: string;
    params: AdminBikeBenefitOrderPartialUpdate;
  }) => {
    const { json } = await httpClient(`${apiUrl}/bike-benefit-orders/${id}`, {
      method: "PATCH",
      body: JSON.stringify(params),
    });
    return json;
  },
  activateFleet: async ({
    id,
    activateVehicles,
  }: {
    id: string;
    activateVehicles: boolean;
  }) => {
    await httpClient(`${apiUrl}/fleets/${id}/activate`, {
      method: "POST",
      body: JSON.stringify({ activate_vehicles: activateVehicles }),
    });
  },
  deactivateFleet: async (id: string) => {
    await httpClient(`${apiUrl}/fleets/${id}/deactivate`, {
      method: "POST",
    });
  },
  activateVehicle: async (id: string) => {
    await httpClient(`${apiUrl}/vehicles/${id}/activate`, {
      method: "POST",
    });
  },
  deactivateVehicle: async (id: string) => {
    await httpClient(`${apiUrl}/vehicles/${id}/deactivate`, {
      method: "POST",
    });
  },
  getBikeFieldSuggestions: async (params: {
    field_name: string;
    q: string;
  }) => {
    const urlParams = new URLSearchParams(params);
    const { json } = await httpClient(
      `${apiUrl}/bikes/field-suggestions?${urlParams.toString()}`,
      {
        method: "GET",
      },
    );
    return json;
  },
  importBikeOrderFromHubspot: async (id: string) => {
    const { json } = await httpClient(`${apiUrl}/hubspot/tickets/${id}`, {
      method: "GET",
    });
    return json;
  },
  updateBike: async ({
    bikeId,
    bikeDetails,
  }: {
    bikeId: string;
    bikeDetails: BikeUpdate;
  }) => {
    const { json } = await httpClient(`${apiUrl}/bikes/${bikeId}`, {
      method: "PUT",
      body: JSON.stringify(bikeDetails),
    });
    return json;
  },
  getUserNotificationsPreferences: async (userId: string) => {
    const { json } = await httpClient(
      `${apiUrl}/notifications-preferences?user_id=${userId}`,
      {
        method: "GET",
      },
    );
    return json;
  },
  downloadDeedOfSale: async (contractId: string, params?: any) => {
    const { response, headers } = await httpClient(
      `${apiUrl}/bike-benefit-contracts/${contractId}/deed-of-sale`,
      {
        method: "POST",
        body: JSON.stringify(params),
        skipParseOnSuccess: true,
      },
    );
    const filename = headers.get("content-disposition")?.split("filename=")[1];
    return { body: await response.blob(), filename };
  },
  checkBikeData: async (checkBikeDataIn) => {
    const { json } = await httpClient(`${apiUrl}/bikes/check-data`, {
      method: "POST",
      body: JSON.stringify(checkBikeDataIn),
    });
    return json;
  },
  planReturnProcessInvoicing: async (planInvoicingIn) => {
    const { json } = await httpClient(
      `${apiUrl}/return-processes/plan-invoicing`,
      {
        method: "POST",
        body: JSON.stringify(planInvoicingIn),
      },
    );
    return json;
  },
  getReturnProcessChanges: async (returnProcessId: string) => {
    const { json } = await httpClient(
      `${apiUrl}/return-processes/${returnProcessId}/changes`,
      {
        method: "GET",
      },
    );
    return json;
  },
  uploadReturnProcessPicture: async (file) => {
    const data = new FormData();
    data.append("file", file);
    const { json } = await httpClient(`${apiUrl}/return-processes/pictures`, {
      method: "POST",
      body: data,
    });
    return json;
  },
  uploadOrganisationPicture: async (file) => {
    const data = new FormData();
    data.append("file", file);
    const { json } = await httpClient(`${apiUrl}/organisations/logos`, {
      method: "POST",
      body: data,
    });
    return json;
  },
  uploadShopPicture: async (file) => {
    const data = new FormData();
    data.append("file", file);
    const { json } = await httpClient(`${apiUrl}/shops/logos`, {
      method: "POST",
      body: data,
    });
    return json;
  },
  getProductVatRates: async (params: { country: string }) => {
    const { json } = await httpClient(
      `${apiUrl}/../api/product-vat-rates?${new URLSearchParams(
        params,
      ).toString()}`,
      {
        method: "GET",
      },
    );
    return json;
  },
  getSwedenSuggestedBenefitValuesForContract: async (
    benefit_bike_contract_id: string,
  ) => {
    const { json } = await httpClient(
      `${apiUrl}/../api/calculations/sweden-suggested-benefit-values-for-contract/${benefit_bike_contract_id}`,
      {
        method: "GET",
      },
    );
    return json;
  },
  getSwedenSuggestedBenefitValuesForOrder: async (
    benefit_bike_order_id: string,
  ) => {
    const { json } = await httpClient(
      `${apiUrl}/../api/calculations/sweden-suggested-benefit-values-for-order/${benefit_bike_order_id}`,
      {
        method: "GET",
      },
    );
    return json;
  },
});

export default createCustomMethodsProvider;
