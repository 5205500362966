import { ReactElement } from "react";

import { Grid } from "@mui/material";
import { capitalize } from "lodash-es";
import {
  BooleanInput,
  FormDataConsumer,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  email,
  required,
  useRecordContext,
} from "react-admin";

import { OrganisationBillingDetailsType } from "@vapaus/generated";

import { SectionTitle, Separator } from "components";
import {
  BILLING_SCHEMES_CHOICES,
  BillingSchemes,
  INVOICING_METHODS_CHOICES,
  InvoicingMethods,
} from "core";

const validateRequired = [required()];
const validateEmail = [required(), email()];
const validateEmailOptional = [email()];

type BillingDetailsFormProps = {
  toolbar?: ReactElement;
};

export const BillingDetailsForm = ({ toolbar }: BillingDetailsFormProps) => {
  const record = useRecordContext();

  return (
    <SimpleForm toolbar={toolbar}>
      <Grid container spacing={2}>
        <Grid item md={6}>
          <SectionTitle title="Billing details" />
          <TextInput source="street_address" fullWidth required />
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextInput source="postal_code" fullWidth required />
            </Grid>
            <Grid item xs={8}>
              <TextInput source="city" fullWidth required />
            </Grid>
          </Grid>
          <SectionTitle title="Contact" />
          <TextInput source="contact_name" fullWidth />
          <TextInput
            source="contact_email"
            validate={validateEmailOptional}
            fullWidth
          />
          <TextInput source="contact_phone" fullWidth />
        </Grid>
        <FormDataConsumer>
          {({ formData }) => (
            <Grid item md={6}>
              <SectionTitle title="Invoicing" />
              <SelectInput
                source="billing_scheme"
                choices={BILLING_SCHEMES_CHOICES}
                defaultValue={BillingSchemes.Standard}
                fullWidth
              />
              <BooleanInput source="use_full_months_invoicing" />
              <SelectInput
                source="invoicing_method"
                choices={INVOICING_METHODS_CHOICES}
                fullWidth
                required
              />
              {formData.invoicing_method == InvoicingMethods.Email && (
                <TextInput
                  source="invoicing_email"
                  validate={validateEmail}
                  fullWidth
                />
              )}
              {formData.invoicing_method == InvoicingMethods.EInvoicing && (
                <>
                  <ReferenceInput
                    source="e_invoicing_operator_id"
                    reference="e-invoicing-operator"
                    filter={{
                      limit: 100,
                      sort_field: "name",
                      sort_direction: "asc",
                    }}
                  >
                    <SelectInput
                      optionText={(choice) =>
                        `${choice.name} ${
                          choice.active
                            ? ""
                            : "(inactive in ERP - should be corrected)"
                        }`
                      }
                      optionValue="id"
                      validate={validateRequired}
                      fullWidth
                    />
                  </ReferenceInput>
                  <TextInput
                    source="e_invoicing_address"
                    validate={validateRequired}
                    fullWidth
                  />
                </>
              )}
              <Separator />
              <SectionTitle title="Netvisor" />
              <SelectInput
                source="type"
                validate={validateRequired}
                choices={Object.values(OrganisationBillingDetailsType).map(
                  (value) => ({ id: value, name: capitalize(value) }),
                )}
                fullWidth
              />
              <TextInput
                source="netvisor_customer_code"
                helperText={
                  record.netvisor_customer_code
                    ? ""
                    : "This field will be populated automatically after the customer is imported to Netvisor"
                }
                disabled
                fullWidth
              />
            </Grid>
          )}
        </FormDataConsumer>
      </Grid>
    </SimpleForm>
  );
};
