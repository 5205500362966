import { FC } from "react";

import CheckIcon from "@mui/icons-material/Check";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useRecordContext, useRefresh } from "react-admin";

import { useVapausMutation } from "hooks";

export interface InvalidateContractModalProps {
  open: boolean;
  onClose: () => void;
}

export const InvalidateContractModal: FC<InvalidateContractModalProps> = ({
  open,
  onClose,
}) => {
  const record = useRecordContext();
  const refresh = useRefresh();
  const { mutate, isLoading } = useVapausMutation("cancelBikeBenefitOrder");
  const onConfirm = () =>
    mutate(record.bike_benefit_order_id, {
      onSuccess: () => {
        refresh();
        onClose();
      },
    });

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      aria-label="Invalidated contract"
    >
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogContent>
        This will invalidate this contract and cancel the related order. All B2B
        billing done up until today should be reverted.
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isLoading}>
          Go back
        </Button>
        <Button
          disabled={isLoading}
          startIcon={<CheckIcon />}
          onClick={onConfirm}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
