import { FC, MouseEvent, ReactNode, useState } from "react";

import MenuIcon from "@mui/icons-material/Menu";
import {
  Box,
  Button,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/system";

interface MenuItemType {
  name: string;
  icon?: ReactNode;
  onClick: () => void;
  disabled?: boolean;
}

interface Props {
  menus: Array<MenuItemType>;
  label?: string;
  tooltip?: string;
}

const DropdownIconMenu: FC<Props> = ({
  menus = [],
  tooltip,
  label = "Actions",
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title={tooltip}>
          <Button onClick={handleClick} startIcon={<MenuIcon />}>
            {label}
          </Button>
        </Tooltip>
      </Box>

      <StyledMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {menus.map((menu) => (
          <MenuItem
            key={menu.name}
            onClick={menu.onClick}
            disabled={menu.disabled}
          >
            {menu?.icon && <ListItemIcon>{menu?.icon}</ListItemIcon>}
            {menu.name}
          </MenuItem>
        ))}
      </StyledMenu>
    </>
  );
};

export default DropdownIconMenu;

const StyledMenu = styled(Menu)(({ theme }) => ({
  overflow: "visible",
  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
  marginTop: theme.spacing(1.5),
}));
