import { useState } from "react";

import FixIcon from "@mui/icons-material/AutoFixHigh";
import BuildIcon from "@mui/icons-material/Build";
import CalculateIcon from "@mui/icons-material/Calculate";
import CancelFillIcon from "@mui/icons-material/Cancel";
import CancelIcon from "@mui/icons-material/DoNotDisturbAlt";
import EditIcon from "@mui/icons-material/Edit";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ReplyIcon from "@mui/icons-material/Reply";
import RequestPageIcon from "@mui/icons-material/RequestPage";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import TransferEmployeeIcon from "@mui/icons-material/TransferWithinAStation";
import TransferEmployerIcon from "@mui/icons-material/Unarchive";
import { CreatePurchaseInvoiceModal } from "components/CreatePurchaseInvoiceModal";
import { BikeBenefitContractState } from "core/benefit";
import { useRecordContext } from "ra-core";
import { useGetOne, useRedirect } from "react-admin";
import { CompleteContractReturnProcessModal } from "resources/BikeBenefitContracts/components/CompleteContractReturnProcessModal";

import {
  BikeBenefitContractAdminRead,
  BikeBenefitContractCancellationReason,
  RedemptionProcessStatus,
  ReturnProcessStatus,
} from "@vapaus/generated";
import { getDaysSinceDate } from "@vapaus/utils";

import { CancelContractModal } from "./CancelContractModal";
import { CancelRedemptionProcessModal } from "./CancelRedemptionProcessModal";
import { CancelReturnProcessModal } from "./CancelReturnProcessModal";
import { CorrectContractBenefitModal } from "./CorrectContractBenefitModal";
import { CreateRedemptionProcessModal } from "./CreateRedemptionProcessModal";
import { CreateReturnProcessModal } from "./CreateReturnProcessModal";
import { DeedOfSaleModal } from "./DeedOfSaleModal";
import { InvalidateContractModal } from "./InvalidateContractModal";
import { RedemptionPriceModal } from "./RedemptionPriceModal";
import { TransferToEmployeeModal } from "./TransferToEmployeeModal";
import { TransferToEmployerModal } from "./TransferToEmployerModal";
import { UpdateContractCancellationModal } from "./UpdateContractCancellationModal";
import { DropdownIconMenu } from "components";

enum MenuType {
  ADD_PURCHASE_INVOICE = "add_purchase_invoice",
  CREATE_SALE_INVOICE = "create_sale_invoice",
  CORRECT_CONTRACT_BENEFIT = "correct_contract_benefit",
  TRANSFER_TO_EMPLOYEE = "transfer_to_employee",
  TRANSFER_TO_EMPLOYER = "transfer_to_employer",
  REDEMPTION_PRICE_CALCULATOR = "redemption_price_calculator",
  CANCEL_CONTRACT = "cancel_contract",
  UPDATE_CONTRACT_CANCELLATION = "update_contract_cancellation",
  GENERATE_DEED_OF_SALE = "generate_deed_of_sale",
  CREATE_REDEMPTION_PROCESS = "create_redemption_process",
  CANCEL_REDEMPTION_PROCESS = "cancel_redemption_process",
  ADD_MAINTENANCE_EVENT = "add_maintenance_event",
  CREATE_RETURN_PROCESS = "create_return_process",
  COMPLETE_RETURN_PROCESS = "complete_return_process",
  CANCEL_RETURN_PROCESS = "cancel_return_process",
  INVALIDATE_CONTRACT = "invalidate_contract",
}

export interface ContractMenuModalProps {
  open: boolean;
  onClose: () => void;
}

const ContractMenu = () => {
  const record = useRecordContext<BikeBenefitContractAdminRead>();
  const [menu, setMenu] = useState<MenuType>();
  const redirect = useRedirect();
  const { data: order } = useGetOne("bike-benefit-orders", {
    id: record?.bike_benefit_order_id,
  });

  const showExceptionEndDate =
    record?.exceptional_end_date &&
    record?.cancellation_reason &&
    [
      BikeBenefitContractCancellationReason.REDEEMED,
      BikeBenefitContractCancellationReason.RETURNED,
      BikeBenefitContractCancellationReason.INSURANCE_THEFT,
      BikeBenefitContractCancellationReason.INSURANCE_ACCIDENT,
      BikeBenefitContractCancellationReason.OTHER,
    ].includes(record.cancellation_reason) &&
    !record?.current_redemption_process_id &&
    !record?.current_return_process_id;

  const isActiveStateAndNoExceptionalEndDate =
    record?.state === BikeBenefitContractState.ACTIVE &&
    !record?.exceptional_end_date;

  const hasContractEndedLessThan60DaysAgoOrActive =
    getDaysSinceDate(record?.exceptional_end_date || record?.end_date || "") ||
    0 < 60;

  const hasMaintenanceBudget = !!record?.monthly_maintenance_budget;

  const { data: redemptionProcess } = useGetOne(
    "redemption-processes",
    {
      id: record?.current_redemption_process_id,
    },
    {
      enabled: !!record?.current_redemption_process_id,
    },
  );
  const isRedemptionProcessOpen = [
    RedemptionProcessStatus.WAITING_FOR_PAYMENT,
    RedemptionProcessStatus.PAYMENT_OVERDUE,
    RedemptionProcessStatus.PENDING_CONTRACT_TERMINATION,
    RedemptionProcessStatus.CONTRACT_TERMINATED,
  ].includes(redemptionProcess?.status);

  const { data: returnProcess } = useGetOne(
    "return-processes",
    {
      id: record?.current_return_process_id,
    },
    {
      enabled: !!record?.current_return_process_id,
    },
  );
  const isReturnProcessOpen = [
    ReturnProcessStatus.DRAFT,
    ReturnProcessStatus.WAITING_FOR_PAYMENT,
    ReturnProcessStatus.PROCESSING,
  ].includes(returnProcess?.status);
  const isReturnProcessProcessing =
    returnProcess?.status === ReturnProcessStatus.PROCESSING;

  const activeStateMenus = isActiveStateAndNoExceptionalEndDate
    ? [
        {
          name: "Correct Contract Benefit",
          icon: <FixIcon />,
          onClick: () => setMenu(MenuType.CORRECT_CONTRACT_BENEFIT),
          menuType: MenuType.CORRECT_CONTRACT_BENEFIT,
          Component: CorrectContractBenefitModal,
        },
        {
          name: "Transfer to Employee",
          icon: <TransferEmployeeIcon />,
          onClick: () => setMenu(MenuType.TRANSFER_TO_EMPLOYEE),
          menuType: MenuType.TRANSFER_TO_EMPLOYEE,
          Component: TransferToEmployeeModal,
        },
        {
          name: "Transfer to Employer",
          icon: <TransferEmployerIcon />,
          onClick: () => setMenu(MenuType.TRANSFER_TO_EMPLOYER),
          menuType: MenuType.TRANSFER_TO_EMPLOYER,
          Component: TransferToEmployerModal,
        },
        {
          name: "Start Redemption Process",
          icon: <ShoppingBasketIcon />,
          onClick: () => setMenu(MenuType.CREATE_REDEMPTION_PROCESS),
          menuType: MenuType.CREATE_REDEMPTION_PROCESS,
          Component: CreateRedemptionProcessModal,
        },
        {
          name: "Start Return Process",
          icon: <ReplyIcon />,
          onClick: () => setMenu(MenuType.CREATE_RETURN_PROCESS),
          menuType: MenuType.CREATE_RETURN_PROCESS,
          Component: CreateReturnProcessModal,
        },
        {
          name: "Redemption Price Calculator",
          icon: <CalculateIcon />,
          onClick: () => setMenu(MenuType.REDEMPTION_PRICE_CALCULATOR),
          menuType: MenuType.REDEMPTION_PRICE_CALCULATOR,
          Component: RedemptionPriceModal,
        },
        {
          name: "Cancel Contract",
          icon: <CancelIcon />,
          onClick: () => setMenu(MenuType.CANCEL_CONTRACT),
          menuType: MenuType.CANCEL_CONTRACT,
          Component: CancelContractModal,
        },
      ]
    : [];
  const exceptionalEndDateMenus = showExceptionEndDate
    ? [
        {
          name: "Modify Cancellation",
          icon: <EditIcon />,
          onClick: () => setMenu(MenuType.UPDATE_CONTRACT_CANCELLATION),
          menuType: MenuType.UPDATE_CONTRACT_CANCELLATION,
          Component: UpdateContractCancellationModal,
        },
      ]
    : [];
  const activePendingRedemptionMenus = isRedemptionProcessOpen
    ? [
        {
          name: "Cancel Redemption Process",
          icon: <ShoppingBasketIcon />,
          onClick: () => setMenu(MenuType.CANCEL_REDEMPTION_PROCESS),
          menuType: MenuType.CANCEL_REDEMPTION_PROCESS,
          Component: CancelRedemptionProcessModal,
        },
      ]
    : [];
  const maintenanceEventsMenus =
    hasContractEndedLessThan60DaysAgoOrActive && hasMaintenanceBudget
      ? [
          {
            name: "Create Maintenance Event",
            icon: <BuildIcon />,
            onClick: () =>
              redirect(
                "create",
                "maintenance-events",
                undefined,
                {},
                {
                  record: {
                    bike_benefit_contract_id: record.id,
                    bike_id: record.bike.id,
                  },
                },
              ),
            menuType: MenuType.ADD_MAINTENANCE_EVENT,
            Component: undefined,
          },
        ]
      : [];
  const activePendingReturnMenus = [
    ...(isReturnProcessProcessing
      ? [
          {
            name: "Complete Return Process",
            icon: <ReplyIcon />,
            onClick: () => setMenu(MenuType.COMPLETE_RETURN_PROCESS),
            menuType: MenuType.COMPLETE_RETURN_PROCESS,
            Component: CompleteContractReturnProcessModal,
          },
        ]
      : []),
    ...(isReturnProcessOpen
      ? [
          {
            name: "Cancel Return Process",
            icon: <ReplyIcon />,
            onClick: () => setMenu(MenuType.CANCEL_RETURN_PROCESS),
            menuType: MenuType.CANCEL_RETURN_PROCESS,
            Component: CancelReturnProcessModal,
          },
        ]
      : []),
  ];

  const invalidateContractMenu =
    BikeBenefitContractState.ACTIVE === record?.state
      ? [
          {
            name: "Invalidate contract",
            icon: <CancelFillIcon />,
            onClick: () => setMenu(MenuType.INVALIDATE_CONTRACT),
            menuType: MenuType.INVALIDATE_CONTRACT,
            Component: InvalidateContractModal,
            disabled: Boolean(
              order?.revises_bike_benefit_contract_id ||
                order?.corrects_bike_benefit_contract_id,
            ),
          },
        ]
      : [];

  const menus = [
    {
      name: "Create Purchase Invoice",
      icon: <CalculateIcon />,
      onClick: () => setMenu(MenuType.ADD_PURCHASE_INVOICE),
      menuType: MenuType.ADD_PURCHASE_INVOICE,
      Component: undefined,
    },
    {
      name: "Create Sale Invoice",
      icon: <ReceiptIcon />,
      onClick: () =>
        redirect(
          "create",
          "invoices/sale-invoices",
          undefined,
          {},
          {
            record: {
              bike_benefit_order_id: record.bike_benefit_order_id,
              bike_benefit_contract_id: record.id,
            },
          },
        ),
      menuType: MenuType.CREATE_SALE_INVOICE,
      Component: undefined,
    },
    {
      name: "Generate Deed of Sale",
      icon: <RequestPageIcon />,
      onClick: () => setMenu(MenuType.GENERATE_DEED_OF_SALE),
      menuType: MenuType.GENERATE_DEED_OF_SALE,
      Component: DeedOfSaleModal,
    },
    ...activeStateMenus,
    ...exceptionalEndDateMenus,
    ...activePendingRedemptionMenus,
    ...maintenanceEventsMenus,
    ...activePendingReturnMenus,
    ...invalidateContractMenu,
  ];

  return (
    <>
      <DropdownIconMenu menus={menus} tooltip="Contract Actions" />
      <CreatePurchaseInvoiceModal
        view="contract"
        open={menu === MenuType.ADD_PURCHASE_INVOICE}
        onClose={() => setMenu(undefined)}
      />
      {menus.map(
        ({ Component, name, menuType }) =>
          Component && (
            <Component
              key={name}
              open={menu === menuType}
              onClose={() => setMenu(undefined)}
            />
          ),
      )}
    </>
  );
};

export default ContractMenu;
